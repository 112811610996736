<template>
  <component
    :is="component"
    :to="to"
    :href="to"
    :disabled="disabled || loading"
    :type="type || 'button'"
    :class="[
      !secondary && !white ? 'bg-primary-600 hover:bg-primary-700' : '',
      !secondary && !white && !loading ? 'text-white' : '',
      secondary
        ? alternative
          ? 'hover:bg-black hover:bg-opacity-5'
          : 'hover:bg-primary-50'
        : '',
      secondary && !loading
        ? alternative
          ? 'text-white'
          : 'text-primary-700'
        : '',
      !secondary ? 'shadow' : '',
      white ? 'bg-white hover:bg-gray-100' : '',
      wide ? 'flex justify-center w-full' : 'inline-flex',
      loading ? 'select-none text-transparent' : '',
      loading || disabled ? 'opacity-70 pointer-events-none' : '',
      large ? 'py-3 px-6' : $slots.default ? 'py-2 px-4' : 'p-2',
      'relative items-center border border-transparent text-sm font-semibold uppercase tracking-wider rounded focus:outline-none'
    ]"
  >
    <app-icon
      v-if="icon"
      :name="icon"
      :type="$slots.default ? 'solid' : 'outline'"
      :class="[
        white ? 'text-gray-400' : '',
        $slots.default ? '-ml-1 mr-2' : ''
      ]"
    />
    <slot />
    <span
      v-if="loading"
      class="absolute inset-0 flex items-center justify-center"
    >
      <app-spinner
        :white="(!secondary && !white) || (secondary && alternative)"
      />
    </span>
  </component>
</template>
<script>
export default {
  props: {
    to: String,
    type: String,
    icon: String,
    wide: Boolean,
    white: Boolean,
    large: Boolean,
    secondary: Boolean,
    alternative: Boolean,
    disabled: Boolean,
    loading: Boolean
  },
  computed: {
    component() {
      return this.to
        ? this.to.startsWith('/')
          ? 'router-link'
          : 'a'
        : 'button';
    }
  }
};
</script>
