<template>
  <app-page large noFooter title="Cadastrar">
    <div class="container -mt-12 grid md:grid-cols-2 content-start pb-16">
      <div class="bg-white shadow rounded py-8 px-4">
        <app-account-creation-form class="max-w-sm mx-auto" />
      </div>
    </div>
  </app-page>
</template>
<script>
export default {
  page: {
    title: 'Criar conta'
  }
};
</script>
