<template>
  <app-page large title="Obrigado!">
    <div class="container -mt-12 grid md:grid-cols-2 content-start pb-16">
      <div class="bg-white shadow rounded p-8">
        <div> Sua assinatura será ativada dentro de alguns segundos. </div>
        <app-button to="/account" class="mt-6"> Acessar conta </app-button>
      </div>
    </div>
  </app-page>
</template>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!from.name) {
        vm.$root.reloadAccountPage = true;
      }
    });
  },
  page: {
    title: 'Obrigado',
    noindex: true
  }
};
</script>
