<template>
  <div
    tabindex="-1"
    class="fixed inset-0 grid outline-none z-10"
    ref="container"
  >
    <div
      class="
        row-start-1 row-end-2
        col-start-1 col-end-2
        bg-gray-300 bg-opacity-80
      "
      v-on:click.self="$emit('close')"
    >
    </div>
    <app-card
      class="
        row-start-1 row-end-2
        col-start-1 col-end-2
        self-center
        mx-auto
        max-w-sm
        overflow-auto
        max-h-full
      "
      style="width: calc(100% - 32px)"
    >
      <slot />
    </app-card>
  </div>
</template>
<script>
import { createFocusTrap } from 'focus-trap';

export default {
  emits: ['close'],
  data() {
    return {
      focusTrap: null
    };
  },
  mounted() {
    const container = this.$refs.container;
    this.focusTrap = createFocusTrap(container, {
      initialFocus: container,
      fallbackFocus: container,
      escapeDeactivates: false,
      allowOutsideClick: true
    });
    this.focusTrap.activate();
  },
  beforeUnmount() {
    this.focusTrap.deactivate();
  }
};
</script>
