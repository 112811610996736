<template>
  <app-page large title="Crie QR codes de forma simples e rápida">
    <!-- hero -->
    <div class="bg-white pb-16">
      <div class="container grid md:grid-cols-2 items-center gap-12">
        <!-- card -->
        <app-card class="-mt-12 py-8 px-4 min-h-128 grid" id="create">
          <app-button
            large
            class="self-center justify-self-center"
            v-on:click="qrcodeModal = true"
          >
            criar qr code
          </app-button>
        </app-card>

        <!-- features -->
        <div>
          <h2 class="text-3xl mb-6">
            O site brasileiro mais completo para criação de QR codes
          </h2>
          <div class="space-y-2">
            <div v-for="f in features" :key="f" class="flex items-center">
              <app-icon
                type="outline"
                name="check-circle"
                class="
                  flex-shrink-0
                  mr-4
                  p-1
                  box-content
                  rounded-full
                  text-green-800
                  bg-green-600 bg-opacity-30
                  fill-white
                "
              />
              <div> {{ f }} </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- dynamic qr codes -->
    <div class="container py-16 grid md:grid-flow-col items-center gap-12">
      <div class="min-w-0">
        <h2 class="text-primary-600 text-3xl mb-4"> QR codes dinâmicos </h2>
        <div>
          Todos os nossos QR codes são dinâmicos, o que significa que eles podem
          ser editados mesmo após a impressão. Portanto, seus QR codes nunca
          ficarão desatualizados, mesmo se você alterar seu site ou suas
          informações de contato!
        </div>
      </div>
      <img src="/img/qrcode.svg" class="w-80 md:order-first mx-auto" />
    </div>

    <!-- testimonials -->
    <div class="bg-white py-16">
      <div class="container grid md:grid-flow-col items-center gap-12">
        <div class="min-w-0">
          <h2 class="text-3xl mb-4" style="max-width: calc(100% - 72px)">
            Mais de <span class="text-primary-600">2000</span> clientes
          </h2>
          <div class="bg-white rounded shadow p-6 relative">
            <div>
              Gostamos muito da plataforma! É fácil de usar e muito superior às
              alternativas. Além disso, a equipe de suporte é atenciosa e
              responde prontamente!
            </div>
            <div class="text-black text-opacity-70 mt-3">
              Davi Santos, Head de Marketing
            </div>
            <img src="/img/quote.svg" class="absolute -top-3 right-6" />
          </div>
        </div>
        <img src="/img/brazil.svg" class="w-80 mx-auto" />
      </div>
    </div>

    <!-- pricing -->
    <div class="container py-16" id="pricing">
      <h2 class="text-3xl text-center">
        Escolha o plano ideal para você ou para seu negócio
      </h2>

      <div class="py-12 flex flex-wrap justify-center -m-3">
        <div
          v-for="plan in plans"
          :key="plan.name"
          class="
            bg-white
            rounded
            shadow
            grid
            justify-items-center
            gap-4
            py-6
            m-3
            w-64
          "
        >
          <img :src="`/img/plan-${plan.id}.svg`" width="60" height="60" />
          <div class="text-center">
            <div class="font-semibold"> {{ plan.name }} </div>
            <div class="text-black text-opacity-70"> {{ plan.limit }} </div>
          </div>
          <div class="text-xl"> {{ plan.price }} / mês </div>
        </div>
      </div>

      <div class="space-y-4">
        <div
          v-for="t in ['Teste por 14 dias grátis', 'Cancele quando quiser']"
          :key="t"
          class="
            bg-white
            shadow
            rounded-full
            w-full
            max-w-xs
            mx-auto
            flex
            items-center
            p-1
          "
        >
          <app-icon
            type="outline"
            name="check-circle"
            class="
              flex-shrink-0
              mr-4
              p-1
              box-content
              rounded-full
              text-green-800
              bg-green-600 bg-opacity-30
              fill-white
            "
          />
          <div> {{ t }} </div>
        </div>
      </div>
    </div>

    <!-- cta -->
    <div
      class="
        py-16
        grid
        justify-center
        bg-gradient-to-tr
        from-gray-900
        to-gray-700
      "
    >
      <app-button large v-on:click="qrcodeModal = true">
        criar qr code
      </app-button>
    </div>
  </app-page>
  <app-qrcode-modal v-if="qrcodeModal" v-on:close="qrcodeModal = false" />
</template>
<script>
export default {
  data() {
    return {
      qrcodeModal: false,
      features: [
        'Crie QR codes que podem ser editados mesmo após a impressão',
        'Saiba quantas pessoas usaram seu QR code',
        'Escolha entre diversos tipos de QR code'
      ],
      plans: [
        {
          id: 'basic',
          name: 'Básico',
          limit: '2 QR codes',
          price: 'R$ 14,90'
        },
        {
          id: 'premium',
          name: 'Premium',
          limit: '50 QR codes',
          price: 'R$ 29,90'
        },
        {
          id: 'vip',
          name: 'VIP',
          limit: 'Ilimitado',
          price: 'R$ 399,90'
        }
      ]
    };
  },
  page: {
    description:
      'Com o Compcodes, sua empresa pode criar e gerenciar QR codes de forma simples e rápida.'
  }
};
</script>
