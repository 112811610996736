<template>
  <app-page large title="Recuperar conta">
    <div class="container -mt-12 grid md:grid-cols-2 content-start pb-16">
      <div v-if="!recipient" class="bg-white shadow rounded py-8 px-4">
        <form class="max-w-sm mx-auto space-y-6" v-on:submit.prevent="submit">
          <app-input :disabled="loading" v-model:options="email" />
          <app-button :loading="loading" wide type="submit">
            Continuar
          </app-button>
        </form>
      </div>

      <!-- success -->
      <div v-else class="bg-white shadow rounded p-8">
        <div>
          Um e-mail foi enviado para
          <span class="font-medium">{{ recipient }}</span> com um link para você
          redefinir sua senha.
        </div>
        <app-button :loading="loading" v-on:click="resendEmail" class="mt-6">
          Reenviar e-mail
        </app-button>
      </div>
    </div>
  </app-page>
</template>
<script>
export default {
  data() {
    return {
      email: {
        value: '',
        label: 'E-mail',
        autocomplete: 'username',
        inputmode: 'email',
        rules: ['required', 'email']
      },
      recipient: null,
      loading: false
    };
  },
  methods: {
    submit() {
      if (this.$root.showErrors(this.email)) return;
      const body = {
        email: this.email.value
      };
      this.loading = true;
      this.api('account.recover', body)
        .then(res => {
          this.recipient = res.data.email;
        })
        .catch(err => {
          const status = (err.response && err.response.status) || 500;
          const messages = {
            404: 'O e-mail inserido não corresponde a nenhuma conta.',
            429: 'Muitas solicitações em pouco tempo. Aguarde alguns segundos.',
            500: 'Algo deu errado, tente novamente.'
          };
          this.$root.notifications.push({
            message: messages[status]
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resendEmail() {
      const body = {
        email: this.recipient
      };
      this.loading = true;
      this.api('account.recover', body)
        .then(() => {
          this.$root.notifications.push({
            message: 'E-mail reenviado com sucesso!'
          });
        })
        .catch(err => {
          const status = err.response && err.response.status;
          if (status === 429) {
            this.$root.notifications.push({
              message:
                'Muitas solicitações em pouco tempo. Aguarde alguns segundos.'
            });
          } else {
            this.$root.notifications.push({
              message: 'Algo deu errado, tente novamente.'
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  page: {
    title: 'Recuperar conta'
  }
};
</script>
