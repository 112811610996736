<template>
  <form class="space-y-6" v-on:submit.prevent="submit">
    <div
      v-if="qrcode"
      class="mb-8 text-center mx-auto"
      style="max-width: 275px"
    >
      <div class="mb-2 text-2xl"> 3. Crie sua conta </div>
      <div class="text-black text-opacity-70">
        Gerencie todos os seus QR codes em um só lugar!
      </div>
    </div>

    <app-input :disabled="loading" v-model:options="email" />
    <app-input :disabled="loading" v-model:options="phone" />
    <app-input :disabled="loading" v-model:options="password" />

    <app-select :disabled="loading" v-model:options="type" />

    <template v-if="type.value === 'individual'">
      <app-input :disabled="loading" v-model:options="name" />
      <app-input :disabled="loading" v-model:options="cpf" />
    </template>

    <template v-if="type.value === 'company'">
      <app-input :disabled="loading" v-model:options="company" />
      <app-input :disabled="loading" v-model:options="cnpj" />
    </template>

    <app-button :loading="loading" wide type="submit"> Criar conta </app-button>
  </form>
</template>
<script>
export default {
  props: {
    qrcode: Object
  },
  data() {
    return {
      email: {
        value: '',
        label: 'E-mail',
        autocomplete: 'username',
        inputmode: 'email',
        rules: ['required', 'email']
      },
      phone: {
        value: '',
        label: 'Telefone com DDD',
        inputmode: 'numeric',
        rules: ['phone'],
        mask: {
          phone: true,
          phoneRegionCode: 'br',
          prefix: '+55 '
        }
      },
      password: {
        value: '',
        label: 'Senha',
        type: 'password',
        autocomplete: 'new-password',
        rules: ['required', 'min-6', 'max-100']
      },
      name: {
        value: '',
        label: 'Nome completo',
        autocomplete: 'name',
        rules: ['required', 'max-100']
      },
      company: {
        value: '',
        label: 'Razão social',
        rules: ['required', 'max-100']
      },
      cpf: {
        value: '',
        label: 'CPF',
        placeholder: '000.000.000-00',
        inputmode: 'numeric',
        rules: ['required', 'cpf'],
        mask: {
          blocks: [3, 3, 3, 2],
          delimiters: ['.', '.', '-'],
          numericOnly: true
        }
      },
      cnpj: {
        value: '',
        label: 'CNPJ',
        placeholder: '00.000.000/0000-00',
        inputmode: 'numeric',
        rules: ['required', 'cnpj'],
        mask: {
          blocks: [2, 3, 3, 4, 2],
          delimiters: ['.', '.', '/', '-'],
          numericOnly: true
        }
      },
      type: {
        value: null,
        required: true,
        label: 'Tipo de conta',
        options: [
          { value: 'individual', text: 'Para mim' },
          { value: 'company', text: 'Para minha empresa' }
        ]
      },
      session: null,
      loading: false
    };
  },
  watch: {
    '$root.session': {
      immediate: true,
      handler(session, oldValue) {
        if (session) {
          const query =
            this.session && session._id === this.session._id
              ? { welcome: true }
              : {};
          if (oldValue !== undefined)
            this.$router.push({ name: 'account-qrcodes', query });
          else this.$router.replace({ name: 'account-qrcodes', query });
        }
      }
    }
  },
  methods: {
    submit() {
      const inputs = [this.email, this.phone, this.password, this.type];

      if (this.type.value === 'individual') inputs.push(this.name, this.cpf);
      if (this.type.value === 'company') inputs.push(this.company, this.cnpj);

      if (this.$root.showErrors(...inputs)) return;

      const body = {
        email: this.email.value,
        phone: this.phone.value,
        password: this.password.value,
        language: 'pt-BR',
        country: 'BR',
        owner: {
          name:
            this.type.value === 'company'
              ? this.company.value
              : this.name.value,
          id:
            this.type.value === 'company'
              ? this.cnpj.value.replace(/\.|-|\//g, '')
              : this.cpf.value.replace(/\.|-/g, '')
        },
        qrcode: this.qrcode || null,
        gclid: this.$root.ls('gclid')
      };
      this.loading = true;
      this.api('account.create', body)
        .then(res => {
          this.session = res.data;
          this.$root.updateSession(res.data);
        })
        .catch(err => {
          const status = err.response && err.response.status;
          if (status === 409) {
            this.$root.notifications.push({
              message: 'O e-mail inserido já está em uso.'
            });
          } else {
            this.$root.notifications.push({
              message: 'Algo deu errado, tente novamente.'
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
