<template>
  <app-page large title="Redefinir senha">
    <div class="container -mt-12 grid md:grid-cols-2 content-start pb-16">
      <!-- error -->
      <div v-if="status === 400" class="bg-white shadow rounded p-8">
        O link que você seguiu expirou.
      </div>

      <!-- success -->
      <div v-else-if="status === 200" class="bg-white shadow rounded p-8">
        <div> Sua senha foi redefinida com sucesso! </div>
        <app-button to="/account" class="mt-6"> Acessar conta </app-button>
      </div>

      <!-- form -->
      <div v-else class="bg-white shadow rounded py-8 px-4">
        <form class="max-w-sm mx-auto space-y-6" v-on:submit.prevent="submit">
          <app-input :disabled="loading" v-model:options="password" />
          <input
            type="text"
            class="hidden"
            autocomplete="username"
            :value="email"
          />
          <app-button :loading="loading" wide type="submit">
            Continuar
          </app-button>
        </form>
      </div>
    </div>
  </app-page>
</template>
<script>
export default {
  data() {
    return {
      password: {
        value: '',
        label: 'Nova senha',
        type: 'password',
        autocomplete: 'new-password',
        rules: ['required', 'min-6', 'max-100']
      },
      email: this.$route.query.email,
      token: this.$route.query.token,
      loading: false,
      status: null
    };
  },
  methods: {
    submit() {
      if (this.$root.showErrors(this.password)) return;
      if (typeof this.token !== 'string' || this.token.length !== 56) {
        this.status = 400;
        return;
      }
      const body = {
        password: this.password.value,
        token: this.token
      };
      this.loading = true;
      this.api('account.password.reset', body)
        .then(res => {
          this.status = 200;
          if (
            this.$root.session &&
            res.data.deletedSessions.includes(this.$root.session._id)
          ) {
            this.$root.updateSession(null);
          }
        })
        .catch(err => {
          this.status = err.response ? err.response.status : 500;
          if (this.status !== 400) {
            this.$root.notifications.push({
              message: 'Algo deu errado, tente novamente.'
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  page: {
    title: 'Redefinir senha',
    noindex: true
  }
};
</script>
