import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import axios from 'axios';
import App from './app';
import router from './router';
import api from './plugins/api';
import page from './plugins/page';
import components from './plugins/components';
import 'tailwindcss/tailwind.css';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

const i18n = createI18n({
  locale: 'pt-BR',
  fallbackLocale: 'en-US'
});

createApp(App)
  .use(router)
  .use(i18n)
  .use(api)
  .use(page)
  .use(components)
  .mount('#app');
