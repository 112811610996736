<template>
  <div>
    <div class="flex">
      <label :for="id" class="text-sm font-medium text-gray-700 cursor-pointer">
        {{ options.label }}
      </label>
    </div>

    <div
      class="mt-1 relative rounded-md shadow-sm"
      :class="disabled ? 'opacity-70' : ''"
    >
      <input
        ref="input"
        :id="id"
        :type="type"
        :disabled="disabled"
        :value="options.value"
        :placeholder="options.placeholder"
        :autocomplete="options.autocomplete"
        :inputmode="options.inputmode || 'text'"
        v-on:input="handleInput"
        v-on:focus="handleFocus"
        v-on:blur="handleBlur"
        :class="[
          trailingIcon ? 'pr-10' : '',
          options.error && options.dirty && !options.focus
            ? 'border-red-300 text-red-900 placeholder-red-300'
            : 'border-gray-300',
          'focus:outline-none focus:ring-primary-600 focus:border-primary-600 block w-full sm:text-sm rounded-md'
        ]"
      />
      <div
        v-if="trailingIcon"
        class="
          absolute
          inset-y-0
          right-0
          pr-3
          flex
          items-center
          pointer-events-none
        "
      >
        <app-icon
          :name="trailingIcon"
          :class="[
            options.error && options.dirty && !options.focus
              ? 'text-red-500'
              : 'text-gray-400',
            options.type === 'password'
              ? 'pointer-events-auto cursor-pointer select-none'
              : ''
          ]"
          v-on:click="showPassword = !showPassword"
        />
      </div>
    </div>

    <p
      v-if="options.error && options.dirty && !options.focus"
      class="mt-2 text-sm text-red-600"
    >
      {{ options.error }}
    </p>
  </div>
</template>
<script>
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.br';

const isCPF = id => {
  if (!/^\d{11}$/.test(id)) {
    return false;
  }

  const numbers = id.match(/\d/g).map(n => Number(n));

  let sum = numbers.slice(0, 9).reduce((sum, n, i) => sum + n * (10 - i), 0);
  let n10 = Math.round((sum * 10) % 11);
  if (n10 === 10) n10 = 0;

  sum = numbers.slice(0, 10).reduce((sum, n, i) => sum + n * (11 - i), 0);
  let n11 = Math.round((sum * 10) % 11);
  if (n11 === 10) n11 = 0;

  return (
    numbers[9] === n10 &&
    numbers[10] === n11 &&
    !numbers.every(n => n === numbers[0])
  );
};

const isCNPJ = id => {
  if (!/^\d{14}$/.test(id)) {
    return false;
  }

  const numbers = id.match(/\d/g).map(n => Number(n));

  let multipliers = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  let sum = numbers
    .slice(0, 12)
    .reduce((sum, n, i) => sum + n * multipliers[i], 0);
  let n13 = Math.round(sum % 11);
  if (n13 < 2) n13 = 0;
  else n13 = 11 - n13;

  multipliers = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  sum = numbers.slice(0, 13).reduce((sum, n, i) => sum + n * multipliers[i], 0);
  let n14 = Math.round(sum % 11);
  if (n14 < 2) n14 = 0;
  else n14 = 11 - n14;

  return (
    numbers[12] === n13 &&
    numbers[13] === n14 &&
    !numbers.every(n => n === numbers[0])
  );
};

const protocols = ['http', 'https'];
const rules = {
  'required': v => !!v || 'Campo obrigatório',
  'email': v => isEmail(v.trim()) || 'E-mail inválido',
  'url': v => isURL(v.trim(), { protocols }) || 'Endereço inválido',
  'cpf': v => isCPF(v.replace(/\D/g, '')) || 'CPF inválido',
  'cnpj': v => isCNPJ(v.replace(/\D/g, '')) || 'CNPJ inválido',
  'phone': v => v.length === 16 || v.length === 17 || 'Telefone inválido',
  'min-6': v => v.length >= 6 || 'Mínimo de 6 caracteres',
  'max-100': v => v.length <= 100 || 'Máximo de 100 caracteres',
  'max-160': v => v.length <= 160 || 'Máximo de 160 caracteres',
  'max-500': v => v.length <= 500 || 'Máximo de 500 caracteres'
};

export default {
  props: {
    options: Object,
    disabled: Boolean
  },
  data() {
    return {
      id: 'id' + Math.random().toFixed(10).slice(2),
      rules,
      showPassword: false
    };
  },
  created() {
    const prefix = this.options.mask && this.options.mask.prefix;
    const value = this.options.value !== prefix ? this.options.value : '';
    const dirty = !!value;
    const error = this.validate(value);
    this.$emit('update:options', { ...this.options, value, error, dirty });
  },
  mounted() {
    const el = this.$refs.input;
    const mask = this.options.mask;

    this.$emit('update:options', { ...this.options, el });

    if (mask) {
      new Cleave(el, {
        ...mask,
        onValueChanged: this.handleInput
      });
    }
  },
  computed: {
    trailingIcon() {
      return this.options.type === 'password'
        ? this.showPassword
          ? 'eye'
          : 'eye-off'
        : this.options.error && this.options.dirty && !this.options.focus
        ? 'exclamation-circle'
        : this.options.trailingIcon;
    },
    type() {
      return this.showPassword ? 'text' : this.options.type || 'text';
    }
  },
  methods: {
    handleInput(e) {
      const value = e.target.value;
      const dirty =
        this.options.dirty ||
        !(this.options.mask && this.options.mask.prefix === value);
      const error = this.validate(value);
      this.$emit('update:options', { ...this.options, value, error, dirty });
    },
    handleFocus() {
      this.$emit('update:options', { ...this.options, focus: true });
    },
    handleBlur() {
      this.$emit('update:options', { ...this.options, focus: false });
    },
    validate(v) {
      return (
        (this.options.rules &&
          this.options.rules
            .map(rule => this.rules[rule](v))
            .filter(res => res && typeof res === 'string')[0]) ||
        null
      );
    }
  }
};
</script>
