<template>
  <div>
    <div class="flex">
      <label :for="id" class="text-sm font-medium text-gray-700 cursor-pointer">
        {{ options.label }}
      </label>
    </div>

    <div class="mt-1" :class="disabled ? 'opacity-70' : ''">
      <textarea
        rows="2"
        ref="input"
        :id="id"
        :disabled="disabled"
        :value="options.value"
        :placeholder="options.placeholder"
        v-on:input="handleInput"
        v-on:focus="handleFocus"
        v-on:blur="handleBlur"
        :class="[
          options.error && options.dirty && !options.focus
            ? 'border-red-300 text-red-900 placeholder-red-300'
            : 'border-gray-300',
          'focus:outline-none focus:ring-primary-600 focus:border-primary-600 block w-full sm:text-sm rounded-md shadow-sm resize-none overflow-y-hidden'
        ]"
      >
      </textarea>
    </div>

    <p
      v-if="options.error && options.dirty && !options.focus"
      class="mt-2 text-sm text-red-600"
    >
      {{ options.error }}
    </p>
  </div>
</template>
<script>
const rules = {
  'required': v => !!v || 'Campo obrigatório',
  'max-160': v => v.length <= 160 || 'Máximo de 160 caracteres',
  'max-500': v => v.length <= 500 || 'Máximo de 500 caracteres'
};

export default {
  props: {
    options: Object,
    disabled: Boolean
  },
  data() {
    return {
      id: 'id' + Math.random().toFixed(10).slice(2),
      rules
    };
  },
  created() {
    const dirty = !!this.options.value;
    const error = this.validate(this.options.value);
    this.$emit('update:options', { ...this.options, error, dirty });
  },
  mounted() {
    const el = this.$refs.input;
    el.style.height = el.scrollHeight + 2 + 'px';

    this.$emit('update:options', { ...this.options, el });
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleInput(e) {
      e.target.style.height = 'auto';
      e.target.style.height = e.target.scrollHeight + 2 + 'px';
      const value = e.target.value;
      const dirty = true;
      const error = this.validate(value);
      this.$emit('update:options', { ...this.options, value, error, dirty });
    },
    handleFocus() {
      this.$emit('update:options', { ...this.options, focus: true });
    },
    handleBlur() {
      this.$emit('update:options', { ...this.options, focus: false });
    },
    handleResize() {
      const el = this.$refs.input;
      el.style.height = 'auto';
      el.style.height = el.scrollHeight + 2 + 'px';
    },
    validate(v) {
      return (
        (this.options.rules &&
          this.options.rules
            .map(rule => this.rules[rule](v))
            .filter(res => res && typeof res === 'string')[0]) ||
        null
      );
    }
  }
};
</script>
