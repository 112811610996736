<template>
  <div class="fixed bottom-2 left-2 right-2 pointer-events-none z-20">
    <transition-group
      tag="div"
      enter-from-class="opacity-0 transform scale-50"
      leave-active-class="hidden"
      class="max-w-md mx-auto grid gap-2"
    >
      <div
        v-for="notification in $root.notifications"
        :key="notification.id"
        class="
          bg-gray-800
          text-gray-200
          p-4
          rounded
          shadow
          transition
          duration-200
          pointer-events-auto
          select-none
          flex
          items-center
        "
      >
        <div class="flex-1 mr-4"> {{ notification.message }} </div>
        <app-icon
          type="outline"
          name="x"
          v-on:click="dismiss(notification)"
          class="
            rounded-full
            p-2
            -m-2
            box-content
            cursor-pointer
            flex-shrink-0
            text-gray-400
            hover:bg-gray-700 hover:text-gray-200
          "
        />
      </div>
    </transition-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: 0
    };
  },
  watch: {
    '$root.notifications': {
      deep: true,
      handler(notifications) {
        notifications
          .filter(notification => !notification.id)
          .forEach(notification => {
            notification.id = this.id + 1;
            this.id = this.id + 1;
            this.dismiss(notification, 5000);
          });
      }
    }
  },
  methods: {
    dismiss(notification, timeout = 0) {
      setTimeout(() => {
        this.$root.notifications = this.$root.notifications.filter(
          n => n !== notification
        );
      }, timeout);
    }
  }
};
</script>
