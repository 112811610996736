import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/home';
import About from '../views/about';
import Account from '../views/account';
import AccountLogIn from '../views/account-log-in';
import AccountCreate from '../views/account-create';
import AccountRecover from '../views/account-recover';
import AccountQrcodes from '../views/account-qrcodes';
// import AccountSubscription from '../views/account-subscription';
// import AccountSupport from '../views/account-support';
// import AccountSettings from '../views/account-settings';
import AccountSubscribed from '../views/account-subscribed';
import AccountResetPassword from '../views/account-reset-password';
import NotFound from '../views/not-found';
import Privacy from '../views/privacy';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/account',
    component: Account,
    children: [
      {
        path: '',
        name: 'account-qrcodes',
        component: AccountQrcodes
      }
      // {
      //   path: 'subscription',
      //   name: 'account-subscription',
      //   component: AccountSubscription
      // },
      // {
      //   path: 'settings',
      //   name: 'account-settings',
      //   component: AccountSettings
      // },
      // {
      //   path: 'support',
      //   name: 'account-support',
      //   component: AccountSupport
      // }
    ]
  },
  {
    path: '/account/log-in',
    name: 'account-log-in',
    component: AccountLogIn
  },
  {
    path: '/account/create',
    name: 'account-create',
    component: AccountCreate
  },
  {
    path: '/account/recover',
    name: 'account-recover',
    component: AccountRecover
  },
  {
    path: '/account/reset-password',
    name: 'account-reset-password',
    component: AccountResetPassword
  },
  {
    path: '/account/subscribed',
    name: 'account-subscribed',
    component: AccountSubscribed
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPos) {
    return savedPos
      ? savedPos
      : {
          el: to.hash || '#header',
          behavior: to.name === from.name ? 'smooth' : 'auto'
        };
  }
});

export default router;
