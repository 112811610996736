<template>
  <span
    class="
      spinner
      animate-spin
      block
      w-6
      h-6
      rounded-full
      border-4 border-black border-opacity-10
    "
    :class="{ white }"
  >
  </span>
</template>
<script>
export default {
  props: {
    white: Boolean
  }
};
</script>
<style>
.spinner {
  border-right-color: theme('colors.primary.600');
  border-top-color: theme('colors.primary.600');
}

.spinner.white {
  border-right-color: theme('colors.white');
  border-top-color: theme('colors.white');
}
</style>
