<template>
  <div class="grid">
    <div class="overflow-auto flex divide-x py-6">
      <div
        v-for="plan in plans"
        :key="plan.id"
        class="flex-grow grid justify-items-center gap-4 px-4"
        style="min-width: 180px"
      >
        <img :src="`/img/plan-${plan.id}.svg`" width="60" height="60" />
        <div class="text-center">
          <div class="font-semibold"> {{ plan.name }} </div>
          <div class="text-black text-opacity-70"> {{ plan.limit }} </div>
        </div>
        <div class="text-xl"> {{ plan.price }} </div>
        <app-button
          v-on:click="subscribe(plan.id)"
          :loading="subscribing === plan.id"
          :disabled="!!subscribing"
        >
          {{ renew ? 'Assinar' : 'Iniciar teste' }}
        </app-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    renew: Boolean
  },
  data() {
    return {
      plans: [
        {
          id: 'basic',
          name: 'Básico',
          limit: '2 QR codes',
          price: 'R$ 14,90'
        },
        {
          id: 'premium',
          name: 'Premium',
          limit: '50 QR codes',
          price: 'R$ 29,90'
        },
        {
          id: 'vip',
          name: 'VIP',
          limit: 'Ilimitado',
          price: 'R$ 399,90'
        }
      ],
      subscribing: null
    };
  },
  methods: {
    subscribe(planId) {
      const body = {
        plan: planId,
        interval: 'monthly'
      };
      this.subscribing = planId;
      this.api('account.subscription.create', body)
        .then(async res => {
          if (!res.data.id) {
            location.href = '/account/subscribed';
            return;
          }
          const { error } = await this.$root.stripe.redirectToCheckout({
            sessionId: res.data.id
          });
          if (error) throw error;
        })
        .catch(() => {
          this.$root.notifications.push({
            message: 'Algo deu errado, tente novamente.'
          });
          this.subscribing = null;
        });
    }
  }
};
</script>
