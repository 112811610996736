<template>
  <component :is="icon" :class="type === 'solid' ? 'h-5 w-5' : 'h-6 w-6'" />
</template>
<script>
export default {
  components: {
    'solid-eye': require('@heroicons/vue/solid/EyeIcon'),
    'solid-eye-off': require('@heroicons/vue/solid/EyeOffIcon'),
    'solid-exclamation-circle': require('@heroicons/vue/solid/ExclamationCircleIcon'),
    'outline-x': require('@heroicons/vue/outline/XIcon'),
    'outline-chat': require('@heroicons/vue/outline/ChatIcon'),
    'outline-star': require('@heroicons/vue/outline/StarIcon'),
    'outline-mail': require('@heroicons/vue/outline/MailIcon'),
    'outline-trash': require('@heroicons/vue/outline/TrashIcon'),
    'outline-chat-alt': require('@heroicons/vue/outline/ChatAltIcon'),
    'outline-globe-alt': require('@heroicons/vue/outline/GlobeAltIcon'),
    'outline-pencil-alt': require('@heroicons/vue/outline/PencilAltIcon'),
    'outline-check-circle': require('@heroicons/vue/outline/CheckCircleIcon'),
    'outline-document-text': require('@heroicons/vue/outline/DocumentTextIcon')
  },
  props: {
    name: String,
    type: {
      type: String,
      default: 'solid'
    }
  },
  computed: {
    icon() {
      return `${this.type}-${this.name}`;
    }
  }
};
</script>
