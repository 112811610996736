<template>
  <app-modal v-on:close="handleClose">
    <div class="py-8 px-4">
      <!-- no subscription -->
      <div v-if="session && !subscription">
        <div class="mb-1 text-2xl text-center"> Escolha seu plano </div>
        <div class="text-black text-opacity-70 text-center mb-8">
          Sua assinatura começará com um teste gratuito de 14 dias.
        </div>
        <app-plan-selector ref="planSelector" />
      </div>

      <!-- invalid subscription -->
      <div v-else-if="session && !subscription.ok">
        <template v-if="subscription.status === 'past_due'">
          <div class="mb-8 text-2xl text-center">
            Assinatura inativa por falta de pagamento
          </div>
          <app-button wide :to="subscription.latestInvoice.url">
            pagar fatura
          </app-button>
        </template>
        <template v-if="subscription.status === 'canceled'">
          <div class="mb-8 text-2xl text-center">
            Sua assinatura foi cancelada
          </div>
          <app-plan-selector renew ref="canceled" />
        </template>
      </div>

      <!-- reached limit -->
      <div v-else-if="!id && reachedLimit">
        <div> {{ reachedLimitMessage }} </div>
        <div class="flex justify-end mt-8">
          <app-button v-on:click="$emit('close')"> ok </app-button>
        </div>
      </div>

      <!-- type -->
      <div v-else-if="step === 'type'">
        <div class="mb-8 text-2xl text-center">
          1. Escolha o tipo do seu QR code
        </div>
        <div class="grid grid-cols-types gap-2">
          <div
            v-for="t in types"
            :key="t.id"
            v-on:click="
              type = t.id;
              step = 'options';
            "
            class="
              border
              rounded
              py-4
              px-2
              cursor-pointer
              select-none
              grid
              justify-items-center
              gap-2
              hover:bg-gray-100
            "
          >
            <app-icon type="outline" :name="t.icon" />
            <div class="text-black text-opacity-70 whitespace-nowrap">
              {{ t.name }}
            </div>
          </div>
        </div>
      </div>

      <!-- options -->
      <form v-else-if="step === 'options'" v-on:submit.prevent="submit">
        <div class="mb-8 text-2xl text-center"> 2. Preencha os detalhes </div>
        <div class="space-y-6">
          <component
            v-for="key in Object.keys($data[type])"
            :key="key"
            :is="$data[type][key].multi ? 'app-textarea' : 'app-input'"
            v-model:options="$data[type][key]"
          />
        </div>
        <div class="flex justify-end space-x-2 mt-8">
          <app-button secondary v-on:click="step = 'type'"> voltar </app-button>
          <app-button type="submit"> continuar </app-button>
        </div>
      </form>

      <!-- name -->
      <form v-else-if="step === 'name'" v-on:submit.prevent="submit">
        <div class="mb-8 text-2xl text-center">
          3. Nomeie seu QR code para identificá-lo com mais facilidade
        </div>
        <app-input :disabled="loading" v-model:options="name" />
        <div class="flex justify-end space-x-2 mt-8">
          <app-button
            secondary
            :disabled="loading"
            v-on:click="step = 'options'"
          >
            voltar
          </app-button>
          <app-button :loading="loading" type="submit"> continuar </app-button>
        </div>
      </form>

      <!-- account -->
      <app-account-creation-form v-else :qrcode="payload" ref="account" />
    </div>
  </app-modal>
</template>
<script>
export default {
  emits: ['close'],
  props: {
    qrcode: Object
  },
  data() {
    return {
      id: null,
      type: null,
      loading: false,
      types: [
        {
          id: 'website',
          name: 'Site',
          icon: 'globe-alt'
        },
        {
          id: 'whatsapp',
          name: 'WhatsApp',
          icon: 'chat'
        },
        {
          id: 'email',
          name: 'E-mail',
          icon: 'mail'
        },
        {
          id: 'text',
          name: 'Texto',
          icon: 'document-text'
        },
        {
          id: 'sms',
          name: 'SMS',
          icon: 'chat-alt'
        },
        {
          id: 'rating',
          name: 'Avaliação',
          icon: 'star'
        }
      ],
      name: {
        value: '',
        label: 'Nome',
        rules: ['required', 'max-100']
      },
      website: {
        url: {
          value: '',
          label: 'Endereço do site',
          inputmode: 'url',
          placeholder: 'www.google.com',
          rules: ['required', 'url', 'max-500']
        }
      },
      whatsapp: {
        number: {
          value: '',
          label: 'Número',
          inputmode: 'numeric',
          rules: ['phone'],
          mask: {
            phone: true,
            phoneRegionCode: 'br',
            prefix: '+55 '
          }
        },
        message: {
          value: '',
          label: 'Mensagem',
          rules: ['max-500'],
          multi: true
        }
      },
      rating: {
        question: {
          value: '',
          label: 'Pergunta',
          placeholder: 'Como foi sua experiência conosco?',
          rules: ['required', 'max-100']
        },
        headline: {
          value: '',
          label: 'Título da página',
          placeholder: 'Nome da empresa',
          rules: ['max-100']
        }
      },
      email: {
        recipient: {
          value: '',
          label: 'Destinatário',
          inputmode: 'email',
          placeholder: 'exemplo@gmail.com',
          rules: ['required', 'email']
        },
        subject: {
          value: '',
          label: 'Assunto',
          rules: ['max-100']
        },
        body: {
          value: '',
          label: 'Corpo',
          rules: ['max-500'],
          multi: true
        }
      },
      sms: {
        recipient: {
          value: '',
          label: 'Destinatário',
          inputmode: 'numeric',
          rules: ['phone'],
          mask: {
            phone: true,
            phoneRegionCode: 'br',
            prefix: '+55 '
          }
        },
        message: {
          value: '',
          label: 'Mensagem',
          rules: ['max-160'],
          multi: true
        }
      },
      text: {
        content: {
          value: '',
          label: 'Conteúdo',
          placeholder: 'Texto de exemplo',
          rules: ['required', 'max-500'],
          multi: true
        }
      },
      step: 'type'
    };
  },
  created() {
    if (this.qrcode && this.qrcode._id) {
      this.id = this.qrcode._id;
      this.type = this.qrcode.type;
      this.name.value = this.qrcode.name;
      Object.entries(this.qrcode.options).forEach(([key, value]) => {
        this[this.type][key].value = value;
      });
      this.step = 'options';
    }
  },
  computed: {
    session() {
      return this.$route.name === 'account-qrcodes';
    },
    payload() {
      if (!this.type) return null;
      return {
        type: this.type,
        name: this.name.value,
        options: Object.entries(this[this.type]).reduce((acc, [key, value]) => {
          acc[key] = value.value;
          return acc;
        }, {})
      };
    },
    subscription() {
      return this.session && this.$root.session.account.billing.subscription;
    },
    reachedLimit() {
      return (
        this.subscription &&
        this.$root.session.account.qrcodes.length >=
          this.subscription.plan.limit
      );
    },
    reachedLimitMessage() {
      const messages = {
        basic: `Você atingiu o limite de 2 QR codes do plano básico. Para criar mais QR codes, assine o plano premium.`,
        premium: `Você atingiu o limite de 50 QR codes do plano premium. Para criar mais QR codes, assine o plano VIP.`,
        vip: `Você atingiu o limite de QR codes do plano VIP.`
      };
      return this.reachedLimit && messages[this.subscription.plan.name];
    }
  },
  methods: {
    submit() {
      if (this.step === 'options') {
        if (this.$root.showErrors(...Object.values(this[this.type]))) {
          return;
        }
        if (this.session) {
          this.step = 'name';
        } else {
          this.name.value = 'Meu primeiro QR code';
          this.step = 'account';
        }
      } else {
        if (this.$root.showErrors(this.name)) {
          return;
        }
        if (!this.id) this.create();
        else this.update();
      }
    },
    create() {
      const account = this.$root.session.account;
      this.loading = true;
      this.api('qrcode.create', { qrcode: this.payload })
        .then(res => {
          this.$root.notifications.push({
            message: 'QR code criado com sucesso!'
          });
          account.qrcodes.unshift(res.data);
          this.$emit('close');
        })
        .catch(err => {
          this.$root.notifications.push({
            message:
              err.response && err.response.status === 403
                ? 'Você atingiu seu limite de QR codes.'
                : 'Algo deu errado, tente novamente.'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      const account = this.$root.session.account;
      this.loading = true;
      this.api('qrcode.update', { qrcodeId: this.id, qrcode: this.payload })
        .then(res => {
          this.$root.notifications.push({
            message: 'QR code salvo com sucesso!'
          });
          const qrcode = account.qrcodes.find(q => q._id === this.id);
          qrcode.name = res.data.name;
          qrcode.type = res.data.type;
          qrcode.options = res.data.options;
          this.$emit('close');
        })
        .catch(() => {
          this.$root.notifications.push({
            message: 'Algo deu errado, tente novamente.'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose() {
      const loading =
        this.session && !this.subscription
          ? this.$refs.planSelector.subscribing
          : this.session && this.subscription.status === 'canceled'
          ? this.$refs.canceled.subscribing
          : this.step === 'account'
          ? this.$refs.account.loading
          : this.loading;
      if (!loading) this.$emit('close');
    }
  },
  watch: {
    '$root.session': {
      immediate: true,
      handler(session) {
        if (session && !this.session && this.step !== 'account') {
          this.$router.push({ name: 'account-qrcodes' });
        }
      }
    }
  }
};
</script>
