<template>
  <div class="min-h-screen flex flex-col">
    <div class="bg-gradient-to-tr from-primary-600 to-indigo-500" id="header">
      <div class="container">
        <div class="flex justify-between items-center py-6">
          <router-link to="/" class="block focus:outline-none">
            <app-logo />
          </router-link>
          <div v-if="$root.session" class="grid grid-flow-col gap-2">
            <app-button
              secondary
              alternative
              :loading="$root.session.exiting"
              v-on:click="$root.exit"
            >
              sair
            </app-button>
            <app-button white to="/account"> Minha conta </app-button>
          </div>
          <div v-else class="grid grid-flow-col gap-2">
            <app-button secondary alternative to="/account/log-in">
              Entrar
            </app-button>
            <app-button white to="/account/create"> Criar conta </app-button>
          </div>
        </div>
        <h1
          class="text-white pt-16 text-4xl font-light"
          :class="large ? 'pb-28' : 'pb-16'"
        >
          {{ title }}
        </h1>
      </div>
    </div>
    <div class="flex-grow bg-gray-100">
      <slot />
    </div>
    <template v-if="!noFooter">
      <div class="bg-white">
        <div
          class="container py-16 grid sm:grid-flow-col justify-center gap-12"
        >
          <div v-for="category in categories" :key="category.name">
            <div class="font-semibold uppercase tracking-wider text-sm mb-2">
              {{ category.name }}
            </div>
            <div v-for="link in category.links" :key="link.text">
              <component
                :is="link.external ? 'a' : 'router-link'"
                :to="link.path"
                :href="link.path"
                :class="[
                  !link.external
                    ? 'text-gray-600 hover:text-gray-500'
                    : 'text-primary-600 hover:text-primary-500',
                  'inline-block'
                ]"
              >
                {{ link.text }}
              </component>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-100 text-center text-gray-600 py-6">
        <div class="container">
          © 2021 Compcodes.
          <span class="whitespace-nowrap">CNPJ: 37.856.589/0001-34.</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    large: Boolean,
    noFooter: Boolean
  },
  data() {
    return {
      categories: [
        {
          name: 'produto',
          links: [
            {
              text: 'Home',
              path: '/'
            },
            {
              text: 'Preços',
              path: '/#pricing'
            },
            {
              text: 'Criar QR code',
              path: '/#create'
            }
          ]
        },
        {
          name: 'empresa',
          links: [
            {
              text: 'Sobre',
              path: '/about'
            },
            {
              text: 'Privacidade',
              path: '/privacy'
            }
          ]
        },
        {
          name: 'contato',
          links: [
            {
              text: 'contato@compcodes.com',
              path: 'mailto:contato@compcodes.com',
              external: true
            }
          ]
        }
      ]
    };
  }
};
</script>
