export default {
  install: app => {
    app.mixin({
      created() {
        const page = this.$options.page;
        if (page) {
          document.title = page.title
            ? `${page.title} - Compcodes`
            : 'Compcodes - Gerador de QR code';

          const desc = document.querySelector('meta[name="description"]');
          if (page.description) {
            if (desc) desc.setAttribute('content', page.description);
            else {
              const el = document.createElement('meta');
              el.setAttribute('name', 'description');
              el.setAttribute('content', page.description);
              document.head.appendChild(el);
            }
          } else if (desc) {
            desc.remove();
          }

          const robots = document.querySelector('meta[name="robots"]');
          if (page.noindex) {
            if (!robots) {
              const el = document.createElement('meta');
              el.setAttribute('name', 'robots');
              el.setAttribute('content', 'noindex');
              document.head.appendChild(el);
            }
          } else if (robots) {
            robots.remove();
          }
        }
      }
    });
  }
};
