<template>
  <app-page v-if="$root.session" large noFooter title="Minha conta">
    <div class="container -mt-12 pb-16">
      <app-card v-if="$root.session.exiting" class="p-8"> Saindo… </app-card>
      <app-card v-else-if="$root.session.loading" class="p-8">
        <app-spinner class="mx-auto" />
      </app-card>
      <app-card v-else-if="!$root.session.account" class="p-8">
        Algo deu errado,
        <a
          v-on:click="$root.retrieveAccount"
          class="text-primary-600 hover:text-primary-500 cursor-pointer"
          >tente novamente</a
        >.
      </app-card>

      <div v-else class="grid md:grid-cols-2 items-start gap-8">
        <div class="grid gap-8">
          <!-- account -->
          <app-card class="min-w-0">
            <div class="border-b p-4 text-2xl"> Dados </div>
            <div class="p-4">
              <app-record name="QR codes">
                {{ account.qrcodes.length }}
              </app-record>
              <app-record name="E-mail"> {{ account.email }} </app-record>
              <app-record
                v-if="account.owner.name"
                :name="
                  account.owner.type === 'individual' ? 'Nome' : 'Razão social'
                "
              >
                {{ account.owner.name }}
              </app-record>
              <app-record
                v-if="accountId"
                :name="account.owner.type === 'individual' ? 'CPF' : 'CNPJ'"
              >
                {{ accountId }}
              </app-record>
            </div>
          </app-card>

          <!-- subscription -->
          <app-card v-if="account.billing.subscription" class="min-w-0">
            <div
              class="
                border-b
                p-4
                grid grid-flow-col
                justify-between
                items-center
              "
            >
              <div class="text-2xl"> Assinatura </div>
              <app-button :loading="openingPortal" v-on:click="manage">
                gerenciar
              </app-button>
            </div>
            <div class="p-4">
              <app-record name="Plano">
                {{ plans[account.billing.subscription.plan.name] }}
              </app-record>
              <app-record name="Limite" v-if="subscription.plan.name !== 'vip'">
                {{ subscription.plan.limit }} QR codes
              </app-record>
              <app-record wrap name="Status">
                <span :class="statusColor">
                  {{ status }}
                  <span v-if="subscription.ok && subscription.endCancel">
                    (não será renovada automaticamente)
                  </span>
                </span>
              </app-record>

              <!-- pay invoice -->
              <app-button
                class="mt-4"
                v-if="subscription.status === 'past_due'"
                :to="subscription.latestInvoice.url"
              >
                pagar fatura
              </app-button>

              <!-- renew subscription -->
              <app-button
                class="mt-4"
                v-if="subscription.status === 'canceled'"
                v-on:click="qrcode = {}"
              >
                renovar
              </app-button>
            </div>
          </app-card>

          <!-- no subscription -->
          <app-card v-if="!account.billing.subscription">
            <div class="border-b p-4">
              <div class="text-2xl mb-1"> Escolha seu plano </div>
              <div class="text-black text-opacity-70">
                Sua assinatura começará com um teste gratuito de 14 dias.
              </div>
            </div>
            <app-plan-selector />
          </app-card>
        </div>

        <!-- qrcodes -->
        <app-card class="min-w-0">
          <div class="p-4 grid grid-flow-col justify-between items-center">
            <div class="text-2xl"> QR codes </div>
            <app-button v-on:click="qrcode = {}"> criar </app-button>
          </div>
          <template v-for="q in account.qrcodes" :key="q._id">
            <div class="p-4 flex border-t">
              <div class="grid justify-items-center content-start">
                <app-qrcode :content="q.content" class="mb-4" />
                <app-button secondary v-on:click="downloadQrcode(q)">
                  baixar
                </app-button>
              </div>
              <div class="border-l ml-4 pl-4 overflow-hidden">
                <app-record name="Tipo"> {{ types[q.type] }} </app-record>
                <app-record name="Nome"> {{ q.name }} </app-record>
                <app-record name="Conteúdo">
                  {{
                    Object.values(q.options)
                      .filter(v => !!v)
                      .join(', ')
                  }}
                </app-record>
                <app-record name="Escaneamentos">
                  {{ q.scanCount }}
                </app-record>

                <!-- buttons -->
                <div class="mt-2">
                  <app-button
                    icon="pencil-alt"
                    secondary
                    :disabled="q.deleting"
                    v-on:click="qrcode = q"
                  />
                  <app-button
                    icon="trash"
                    secondary
                    :loading="q.deleting"
                    v-on:click="deleteQrcode(q)"
                  />
                </div>
              </div>
            </div>
          </template>
          <div v-if="!account.qrcodes.length" class="p-4 border-t">
            Sua lista está vazia.
          </div>
        </app-card>

        <!-- qrcode editor -->
        <app-qrcode-modal
          v-if="qrcode"
          :qrcode="qrcode"
          v-on:close="qrcode = null"
        />

        <!-- welcome -->
        <app-modal v-if="welcome" v-on:close="welcome = false">
          <div class="p-4">
            <img src="/img/check.svg" class="w-48 mx-auto" />
            <div class="py-6 text-center">
              Parabéns, sua conta foi criada com sucesso! Escolha seu plano e
              aproveite um teste de 14 dias sem compromisso.
            </div>
            <app-button wide v-on:click="welcome = false"> ok </app-button>
          </div>
        </app-modal>
      </div>
    </div>
  </app-page>
</template>
<script>
import QRCode from 'qrcode-svg';
import { saveAs } from 'file-saver';

export default {
  data() {
    return {
      openingPortal: false,
      welcome: false,
      qrcode: null,
      types: {
        website: 'site',
        whatsapp: 'WhatsApp',
        rating: 'avaliação',
        email: 'e-mail',
        sms: 'SMS',
        text: 'texto'
      },
      plans: {
        basic: 'básico',
        premium: 'premium',
        vip: 'VIP'
      }
    };
  },
  computed: {
    account() {
      return this.$root.session && this.$root.session.account;
    },
    accountId() {
      if (!this.account) return null;
      const id = this.account.owner.id;
      if (!id) return null;
      return this.account.owner.type === 'individual'
        ? `${id.slice(0, 3)}.${id.slice(3, 6)}.${id.slice(6, 9)}-${id.slice(9)}`
        : `${id.slice(0, 2)}.${id.slice(2, 5)}.${id.slice(5, 8)}/${id.slice(
            8,
            12
          )}-${id.slice(12)}`;
    },
    subscription() {
      return this.account && this.account.billing.subscription;
    },
    status() {
      const statuses = {
        trialing: 'ativa (período teste)',
        active: 'ativa',
        canceled: 'cancelada',
        past_due: 'inativa por falta de pagamento'
      };
      return this.subscription && statuses[this.subscription.status];
    },
    statusColor() {
      return (
        this.subscription &&
        (this.subscription.ok && !this.subscription.endCancel
          ? 'text-green-700'
          : 'text-red-600')
      );
    }
  },
  watch: {
    '$root.session': {
      immediate: true,
      handler(session, oldValue) {
        if (!session) {
          if (oldValue !== undefined)
            this.$router.push({ name: 'account-log-in' });
          else this.$router.replace({ name: 'account-log-in' });
        }
      }
    }
  },
  created() {
    if (this.account && this.$root.reloadAccountPage) {
      this.$root.retrieveAccount();
    }
    this.$root.reloadAccountPage = false;
    if ('welcome' in this.$route.query) {
      this.welcome = true;
      this.$router.replace({ query: null });
    }
  },
  methods: {
    downloadQrcode(qrcode) {
      if (!this.subscription || !this.subscription.ok) {
        this.qrcode = {};
        return;
      }
      const q = new QRCode({
        content: qrcode.content,
        container: 'svg-viewbox',
        join: true
      });
      const svg = q.svg();
      saveAs(
        new Blob([svg], { type: 'image/svg+xml' }),
        `QR code - ${qrcode.name}.svg`
      );
    },
    deleteQrcode(qrcode) {
      if (!confirm('Tem certeza que deseja excluir este QR code?')) {
        return;
      }
      const account = this.account;
      qrcode.deleting = true;
      this.api('qrcode.delete', { qrcodeId: qrcode._id })
        .then(() => {
          this.$root.notifications.push({
            message: 'QR code deletado com sucesso!'
          });
          account.qrcodes = account.qrcodes.filter(q => q !== qrcode);
        })
        .catch(() => {
          this.$root.notifications.push({
            message: 'Algo deu errado, tente novamente.'
          });
        })
        .finally(() => {
          qrcode.deleting = false;
        });
    },
    manage() {
      this.openingPortal = true;
      this.api('account.subscription.manage')
        .then(res => {
          location.href = res.data.url;
        })
        .catch(() => {
          this.$root.notifications.push({
            message: 'Algo deu errado, tente novamente.'
          });
          this.openingPortal = false;
        });
    }
  },
  page: {
    title: 'Minha conta',
    noindex: true
  }
};
</script>
