<template>
  <app-page :title="$options.page.title">
    <!-- our product -->
    <div class="bg-white">
      <div class="container py-16">
        <h2 class="text-3xl"> Nosso produto </h2>
        <p class="mt-4">
          Compcodes é uma ferramenta online que possibilita, de forma simples e
          rápida, a criação e o gerenciamento de QR codes.
        </p>
      </div>
    </div>

    <!-- our mission -->
    <div class="bg-gradient-to-tr from-gray-900 to-gray-700 text-white">
      <div class="container py-16">
        <h2 class="text-3xl slashes"> Nossa missão </h2>
        <p class="mt-4">
          O Compcodes surgiu com o objetivo de ajudar e crescer junto com você,
          utilizando o poder dos QR codes. Por trás da nossa plataforma, está o
          nosso desejo de possibilitar a criação de QR codes de forma simples e
          rápida, para você utilizar em qualquer lugar e potencializar seu
          negócio.
        </p>
      </div>
    </div>

    <!-- our history -->
    <div class="bg-gray-100">
      <div class="container py-16">
        <h2 class="text-3xl text-primary-600"> Nossa história </h2>
        <p class="mt-4">
          O Compcodes foi fundado em Ribeirão Preto - SP. Em um curto período de
          tempo, com a ajuda de uma equipe motivada e engajada, conquistamos a
          confiança de centenas de empresas pelo Brasil, desde pequenos negócios
          locais até grandes corporações. Desenvolvemos inúmeras soluções e
          funcionalidades pelo caminho, e vamos continuar trabalhando para
          ajudar cada vez mais nossos clientes e parceiros.
        </p>
      </div>
    </div>
  </app-page>
</template>
<script>
export default {
  page: {
    title: 'Sobre nós'
  }
};
</script>
<style>
.slashes::after {
  content: '///';
  @apply font-medium text-white text-opacity-10 tracking-tighter;
}
</style>
