<template>
  <app-page :title="$options.page.title">
    <div class="container py-16 space-y-4">
      <p>
        Sua privacidade é importante para nós. É política do Compcodes respeitar
        sua privacidade em relação a qualquer informação sua que possamos
        coletar no site
        <router-link to="/" class="text-primary-600 hover:text-primary-500"
          >Compcodes</router-link
        >, e outros sites que possuímos e operamos.
      </p>
      <p>
        Solicitamos informações pessoais apenas quando realmente precisamos
        delas para lhe fornecer um serviço. Nós as coletamos por meios justos e
        legais, com o seu conhecimento e consentimento. Também lhe informamos
        por que estamos coletando e como serão utilizadas.
      </p>
      <p>
        Somente retemos as informações coletadas durante o tempo necessário para
        lhe fornecer o serviço solicitado. Quando armazenamos dados, protegemos
        dentro de meios comercialmente aceitáveis para evitar perdas e roubos,
        bem como acesso não autorizado, divulgação, cópia, uso ou modificação.
      </p>
      <p>
        Não compartilhamos informações de identificação pessoal publicamente ou
        com terceiros, exceto quando exigido por lei.
      </p>
      <p>
        Nosso site pode conter links para sites externos que não são operados
        por nós. Esteja ciente de que não temos controle sobre o conteúdo e as
        práticas desses sites, e não podemos aceitar responsabilidade por suas
        respectivas políticas de privacidade.
      </p>
      <p>
        Você é livre para recusar a nossa solicitação de informações pessoais,
        entendendo que talvez não possamos fornecer alguns dos serviços
        desejados.
      </p>
      <p>
        O uso continuado de nosso site será considerado como aceitação de nossas
        práticas em torno de privacidade e informações pessoais. Se você tiver
        alguma dúvida sobre como lidamos com dados de usuários e informações
        pessoais, entre em contato conosco.
      </p>
      <p> Esta política é efetiva a partir de agosto de 2020. </p>
    </div>
  </app-page>
</template>
<script>
export default {
  page: {
    title: 'Política de privacidade'
  }
};
</script>
