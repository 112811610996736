import axios from 'axios';

export default {
  install: app => {
    app.mixin({
      data() {
        return {
          cancelSource: axios.CancelToken.source()
        };
      },
      beforeUnmount() {
        this.cancelSource.cancel();
      },
      methods: {
        api(service, params) {
          return axios.post(service, params || {}, {
            cancelToken: this.cancelSource.token
          });
        }
      }
    });
  }
};
