<template>
  <div>
    <div v-if="loading"> Loading... </div>
    <div v-else-if="!qrcodes">
      An error occurred, <a v-on:click="listQrcodes"> try again </a>
    </div>
    <div v-else-if="!qrcodes.length">
      <div> No qrcodes </div>
      <app-button v-on:click="qrcode = {}"> criar qr code </app-button>
    </div>
    <div v-else>
      <div class="sm:flex sm:items-center sm:justify-between mb-8 px-4 sm:px-0">
        <h2
          class="
            text-2xl
            font-bold
            leading-7
            text-gray-900
            sm:text-3xl sm:truncate
          "
        >
          QR codes
        </h2>
        <div class="mt-4 sm:mt-0">
          <app-button white icon="plus" v-on:click="qrcode = {}">
            Novo QR code
          </app-button>
        </div>
      </div>
      <div>
        <div v-for="q in qrcodes" :key="q._id">
          <app-qrcode :content="q.content" />
          <div> {{ q.name }} </div>
          <div> {{ q.type }} </div>
          <div> {{ q.content }} </div>
          <div> {{ q.scanCount }} </div>
          <app-button v-on:click="downloadQrcode(q)" :disabled="q.deleting">
            baixar
          </app-button>
          <app-button v-on:click="qrcode = q" :disabled="q.deleting">
            editar
          </app-button>
          <app-button v-on:click="deleteQrcode(q)" :loading="q.deleting">
            deletar
          </app-button>
        </div>
      </div>
    </div>

    <app-qrcode-modal
      v-if="qrcode"
      :qrcode="qrcode"
      v-on:close="qrcode = null"
    />
  </div>
</template>
<script>
import QRCode from 'qrcode-svg';
import { saveAs } from 'file-saver';

export default {
  data() {
    return {
      qrcode: null,
      qrcodes: null,
      loading: false
    };
  },
  created() {
    this.listQrcodes();
  },
  methods: {
    listQrcodes() {
      this.loading = true;
      this.api('qrcode.list')
        .then(res => {
          this.qrcodes = res.data.qrcodes;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    deleteQrcode(qrcode) {
      if (!confirm('Tem certeza que deseja excluir este QR code?')) {
        return;
      }
      qrcode.deleting = true;
      this.api('qrcode.delete', { qrcodeId: qrcode._id })
        .then(() => {
          this.qrcodes = this.qrcodes.filter(q => q !== qrcode);
        })
        .catch(() => {
          // TODO: handle error
        })
        .finally(() => {
          qrcode.deleting = false;
        });
    },
    downloadQrcode(qrcode) {
      const q = new QRCode({
        content: qrcode.content,
        container: 'svg-viewbox',
        join: true
      });
      const svg = q.svg();
      saveAs(
        new Blob([svg], { type: 'image/svg+xml' }),
        `QR code - ${qrcode.name}.svg`
      );
    }
  },
  page: {
    title: 'Minha conta',
    noindex: true
  }
};
</script>
