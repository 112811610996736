<template>
  <div v-html="image"></div>
</template>
<script>
import QRCode from 'qrcode-svg';

export default {
  props: {
    content: String
  },
  computed: {
    image() {
      const qrcode = new QRCode({
        content: this.content,
        padding: 0,
        height: 87,
        width: 87,
        join: true
      });
      return qrcode.svg();
    }
  }
};
</script>
