<template>
  <app-page large noFooter title="Acessar conta">
    <div class="container -mt-12 grid md:grid-cols-2 content-start pb-16">
      <div class="bg-white shadow rounded py-8 px-4">
        <form class="max-w-sm mx-auto space-y-6" v-on:submit.prevent="submit">
          <app-input :disabled="loading" v-model:options="email" />
          <app-input :disabled="loading" v-model:options="password" />
          <div class="text-right text-sm">
            <router-link
              to="/account/recover"
              class="font-medium text-primary-600 hover:text-primary-500"
            >
              Esqueci minha senha
            </router-link>
          </div>
          <app-button :loading="loading" wide type="submit">
            Continuar
          </app-button>
        </form>
      </div>
    </div>
  </app-page>
</template>
<script>
export default {
  data() {
    return {
      email: {
        value: '',
        label: 'E-mail',
        autocomplete: 'username',
        inputmode: 'email',
        rules: ['required', 'email']
      },
      password: {
        value: '',
        label: 'Senha',
        type: 'password',
        autocomplete: 'current-password',
        rules: ['required']
      },
      loading: false
    };
  },
  watch: {
    '$root.session': {
      immediate: true,
      handler(session, oldValue) {
        if (session) {
          if (oldValue !== undefined)
            this.$router.push({ name: 'account-qrcodes' });
          else this.$router.replace({ name: 'account-qrcodes' });
        }
      }
    }
  },
  methods: {
    submit() {
      if (this.$root.showErrors(this.email, this.password)) return;
      const body = {
        email: this.email.value,
        password: this.password.value
      };
      this.loading = true;
      this.api('session.create', body)
        .then(res => {
          this.$root.updateSession(res.data);
        })
        .catch(err => {
          const status = (err.response && err.response.status) || 500;
          const messages = {
            404: 'O e-mail inserido não corresponde a nenhuma conta.',
            400: 'A senha inserida está incorreta.',
            500: 'Algo deu errado, tente novamente.'
          };
          this.$root.notifications.push({
            message: messages[status]
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  page: {
    title: 'Acessar conta'
  }
};
</script>
