<template>
  <app-page :title="$options.page.title" />
</template>
<script>
export default {
  page: {
    title: 'Página não encontrada'
  }
};
</script>
