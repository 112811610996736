<template>
  <div>
    <div class="flex">
      <label :for="id" class="text-sm font-medium text-gray-700 cursor-pointer">
        {{ options.label }}
      </label>
    </div>

    <select
      :id="id"
      :disabled="disabled"
      ref="input"
      class="
        mt-1
        block
        w-full
        pl-3
        pr-10
        py-2
        text-base
        focus:outline-none focus:ring-primary-600 focus:border-primary-600
        sm:text-sm
        rounded-md
        shadow-sm
      "
      :class="[
        disabled ? 'opacity-70' : '',
        options.error && options.dirty && !options.focus
          ? 'border-red-300 text-red-900'
          : 'border-gray-300'
      ]"
      :value="options.value"
      v-on:input="handleInput"
      v-on:focus="handleFocus"
      v-on:blur="handleBlur"
    >
      <option disabled hidden :value="null"> Selecionar </option>
      <option
        v-for="option in options.options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.text }}
      </option>
    </select>

    <p
      v-if="options.error && options.dirty && !options.focus"
      class="mt-2 text-sm text-red-600"
    >
      {{ options.error }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    options: Object,
    disabled: Boolean
  },
  data() {
    return {
      id: 'id' + Math.random().toFixed(10).slice(2)
    };
  },
  mounted() {
    const el = this.$refs.input;
    this.$emit('update:options', { ...this.options, el });
  },
  methods: {
    handleInput(e) {
      const value = e.target.value;
      this.$emit('update:options', { ...this.options, value });
    },
    handleFocus() {
      this.$emit('update:options', { ...this.options, focus: true });
    },
    handleBlur() {
      this.$emit('update:options', { ...this.options, focus: false });
    }
  },
  watch: {
    'options.value': {
      immediate: true,
      handler() {
        const error =
          this.options.required && !this.options.value
            ? 'Campo obrigatório'
            : null;
        this.$emit('update:options', { ...this.options, error });
      }
    }
  }
};
</script>
