<template>
  <div :class="{ truncate: !wrap }">
    <span class="text-black text-opacity-70"> {{ name }}: </span>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    wrap: Boolean
  }
};
</script>
